<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <app-btn @click="dialog = true">
        <v-icon>mdi-plus-thick</v-icon> Add New Material
      </app-btn>
    </v-col>
    <material-card
      icon="mdi-account-group"
      icon-small
      color="primary"
      title="Materials"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="materials"
          :search.sync="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isDescending"
          :footer-props="{
            showFirstLastPage: true,
          }"
          single-expand
          :expanded.sync="expanded"
          item-key="materialId"
          show-expand
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="MaterialForm">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.materialName"
                            label="Name"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.meterialDescription"
                            label="Description"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.materialSKU"
                            label="SKU"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.cost"
                            label="Cost"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.price"
                            label="Price"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.unitOfMeasure"
                            label="Unit Of Measurement"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.minimumMeasurement"
                            label="Minimum Measurement"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.fixedMeasurement"
                            label="Fixed Measurement"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.minimumMeasurement`]="{ item }">
            {{ item.minimumMeasurement + item.unitOfMeasure }}
          </template>
          <template v-slot:[`item.fixedMeasurement`]="{ item }">
            {{ item.fixedMeasurement + item.unitOfMeasure }}
          </template>
          <template v-slot:[`item.link`]="{ item }">
            <v-icon color="secondary">mdi-file-download </v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="primary pa-2">
              <v-data-table
                :headers="sub_headers"
                :items="item.materialLocations ? item.materialLocations : []"
              ></v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
export default {
  name: "DataTablesView",
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Image",
        align: "start",
        value: "image",
      },
      {
        text: "EAN",
        value: "ean",
      },
      { text: "Material", value: "materialName" },
      { text: "SKU", value: "materialSKU" },
      { text: "Description", value: "meterialDescription" },
      { text: "Cost", value: "cost" },
      { text: "Price", value: "price" },
      {
        text: "Minimum Measurement",
        value: "minimumMeasurement",
      },
      { text: "Fixed Measurement", value: "fixedMeasurement" },
      { text: "Link", value: "link" },
      { text: "Actions", align: "end", value: "actions", sortable: false },
    ],
    sub_headers: [
      {
        text: "Location",
        align: "start",
        value: "location.locationName",
      },
      { text: "Quantity", value: "quantity" },
    ],
    sortBy: "materialId",
    isDescending: true,
    materials: [],
    expanded: [],
    editedIndex: -1,
    editedItem: {
      materialSKU: "",
      materialName: "",
      meterialDescription: "",
      price: "",
      cost: "",
      unitOfMeasure: "",
      minimumMeasurement: "",
      fixedMeasurement: "",
    },
    defaultItem: {
      materialSKU: "",
      materialName: "",
      meterialDescription: "",
      price: 0,
      cost: 0,
      unitOfMeasure: "",
      minimumMeasurement: 0,
      fixedMeasurement: 0,
    },
    search: undefined,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Material" : "Edit Material";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http.get("/Material/GetAllWithLocations").then((response) => {
        this.materials = response.data.data;
      });
    },

    editItem(item) {
      this.editedIndex = this.materials.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.materials.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let materialIndex = this.editedIndex;
      this.$http
        .post("/Material/Trash", {
          materialid: this.editedItem.materialId,
        })
        .then((response) => {
          if (response.data.success) {
            this.materials.splice(materialIndex, 1);
            this.$toast.success("Material deleted successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(
              "Something went wrong while deleting the material.",
              "Error",
              {
                position: "topRight",
              }
            );
          }
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.MaterialForm.validate()) {
        let materialIndex = this.editedIndex;
        if (materialIndex > -1) {
          this.$http
            .patch("/Material/Update", this.editedItem)
            .then((response) => {
              if (response.data.isSuccess) {
                this.materials.splice(
                  materialIndex,
                  1,
                  response.data.outputObject
                );
                this.$toast.success(
                  "Material Updated successfully.",
                  "Success",
                  {
                    position: "topRight",
                  }
                );
              } else {
                this.$toast.error(
                  "Something went wrong while updating the material.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            });
        } else {
          this.$http
            .post("/Material/Create", this.editedItem)
            .then((response) => {
              if (response.data.isSuccess) {
                this.materials.unshift(response.data.outputObject);
                this.$toast.success("Material added successfully.", "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(response.data.message, "Error", {
                  position: "topRight",
                });
              }
            });
        }
        this.close();
      }
    },
  },
};
</script>
